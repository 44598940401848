.app{
  padding: 20px;
}
.stage{
  font-size: 33px;
  border-left: 5px solid #00357a;
  padding-left: 10px;
  padding-top: 3px;
  .stageNumber{
    color: #00357a;
    font-weight: bold;
  }
}
.errorInfo{
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #9c0000;
  padding: 3px 0 3px 0;
}
.stageContent{
  padding: 8px 0 8px 0;
}
.nextButton{
  margin: 10px auto 3px auto;
  color: white;
  font-weight: bold;
  padding: 10px;
  font-size: 25px;
  width: 140px;
  text-align: center;
  border-radius: 25px;
  cursor: pointer;
  transition: 0.2s ease-in background-color;
  background-color: rgb(13, 155, 8);
  &:hover{
    background-color: rgb(14, 105, 11);
  }
}