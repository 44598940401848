.results{
  .resultItem{
    background-color: #ececec;
    border: 2px solid #8d8d8d;
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
    font-size: 26px;
    overflow: hidden;
    box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.4);
    .position{
      padding: 3px;
      width: 40px;
      border-right: 2px solid #8d8d8d;
      background-color: #fff;
      text-align: center;
    }
    .name{
      padding: 3px;
      width: 300px;
      border-right: 2px solid #8d8d8d;
    }
    .time{
      width: calc(100% - 300px - 40px);
      padding: 3px;
      background-color: #fff;
    }
  }
}