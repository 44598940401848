@font-face {
  font-family: "Nunito-regular";
  src: url("../assets/Nunito-Regular.ttf");
}

body{
  font-family: "Nunito-regular";

  margin: 0px;
}
*,*::after,*::before{
  box-sizing: border-box;
}